<template>
  <div v-if="userInfo != null">
    <div class="orderDetail" v-if="detail.id">
      <div class="box">
        <div class="hstatus">{{statusList[detail.status]}}</div>
        <div class="contact" v-if="detail.name != null && detail.mobile != null">
          <p><van-icon name="manager" /> {{detail.name}}</p>
          <p class="mobile"><van-icon name="phone" /> {{detail.mobile}}</p>
        </div>
      </div>
      <div class="box mt10">
        <van-row type="flex" class="ginfo" :gutter="16">
          <van-col span="6"><img :src="detail.goodInfo.titleimg"/></van-col>
          <van-col span="18">
            <p class="title">{{detail.goodInfo.title}}</p>
            <p class="price"><span>¥<em>{{detail.uprice}}</em>元</span></p>
            <p class="stock">数量：{{detail.num}}</p>
          </van-col>
          <van-col span="24">
            <van-divider :style="{height:'20px',margin:'0px'}"/>
          </van-col>
          <van-col span="24">
            <van-row type="flex" justify="space-between" align="center" class="total_price">
              <van-col>合计</van-col>
              <van-col>{{detail.money}}元</van-col>
            </van-row>
          </van-col>
        </van-row>
      </div>
      <div class="box mt10">
        <div class="h4">订单详情</div>
        <div class="con">
          <p>订单编号：{{detail.ordernumber}}</p>
          <p>下单时间：{{detail.create_time}}</p>
          <p v-if="detail.payment_time != null">支付时间：{{detail.payment_time}}</p>
          <p v-if="detail.lq_time != null">领取时间：{{detail.lq_time}}</p>
        </div>
    
      </div>
      <div class="box mt10">
        <shopInfo :data="detail.hdInfo.shopInfo" v-if="detail.id"/>
      </div>
      <van-form @submit="payOrder">
        
        <van-cell-group class="mt10" v-if="detail.name==null || detail.mobile==null">
          <van-field v-model="json.name" label="姓名" placeholder="请输入姓名" input-align="right" :rules="[{ required: true,message: '请输入姓名'}]" :error="false" error-message-align="right"/>
          <van-field v-model="json.mobile" label="手机" placeholder="请输入联系方式" input-align="right" :rules="[{ required: true,validator:validatorPhone,message: '请输入正确的手机号码'}]" :error="false" error-message-align="right"/>
        </van-cell-group>
        
        <div class="t-c" style="width:50%;margin:20px auto;" v-if="detail.status==1 && detail.hdInfo.state==1"><van-button type="danger" round :loading="loading" native-type="submit" block>立即支付 {{detail.money}}</van-button></div>
      </van-form>
      
      
    </div>
    <global :hdid="detail.hdid" v-if="detail.id"/>
  </div>
</template>

<script>
  import global from './components/global'
  import shopInfo from './components/shopInfo'
  import {GetorderInfo,OrderCreate} from "./services/services";
  import {mapGetters} from "vuex";
  export default {
    name:'orderDetail',
    components: {shopInfo,global},
    data() {
      return {
        statusList:['已取消','待支付','已支付','已完成'],
        orderId:0,
        detail:{},
        loading:false,
        jsApiParameters:{},
        json:{
          name:'',
          mobile:''
        }
      }
    },
    computed:{
      ...mapGetters(['snapshotuser','userInfo']),
    },
    mounted() {
      const {orderid} = this.$route.params
      if(orderid==undefined){
        this.$toast.fail('参数错误');
      }else{
        this.orderId=parseInt(orderid)
        this.findDetail()
      }
    },
    methods: {
      validatorPhone(val) {
        return /^1[3456789]\d{9}$/.test(val);
      },
      findDetail(){
        GetorderInfo({id:this.orderId,uid:this.uid}).then(result => {
          if(result.data.code==1){
            this.detail=result.data.data
          }else{
            this.$toast(result.data.msg)
          }
        })
      },
      payOrder(){
        this.loading=true
        let json={
          orderId:this.detail.id,
          hdid:this.detail.hdid
        }
        if((this.detail.mobile==null || this.detail.name==null) && this.json.mobile!='' && this.json.name !=''){
          json={
            ...json,
            ...this.json
          }
        }
        OrderCreate(json).then(result => {
          this.loading=false
          if(result.data.code==1){
            const {jsApiParameters} = result.data.data
            this.jsApiParameters=jsApiParameters
            this.callpay()
          }else{
            this.$toast(result.data.msg)
          }
        })
      },
      callpay(){
        if (typeof WeixinJSBridge == "undefined"){
          if( document.addEventListener ){
            document.addEventListener('WeixinJSBridgeReady', this.jsApiCall(), false);
          }else if (document.attachEvent){
            document.attachEvent('WeixinJSBridgeReady', this.jsApiCall());
            document.attachEvent('onWeixinJSBridgeReady', this.jsApiCall());
          }
        }else{
          this.jsApiCall();
        }
      },
      jsApiCall(){
        WeixinJSBridge.invoke(
          'getBrandWCPayRequest',
          {
            ...this.jsApiParameters
          },(res)=>{
            WeixinJSBridge.log(res.err_msg);
            let url = ''
            switch (this.detail.hdInfo.hd_type) {
              case 1:
                url = this.detail.hdInfo.url+'/wx/prize/'+this.detail.hdid
                break;
              default:
                url = this.detail.hdInfo.url+'/wx/order/'+this.detail.hdid
            }
            
            if(res.err_msg == 'get_brand_wcpay_request:ok'){
              location.href = url
            }else if(res.err_msg == 'get_brand_wcpay_request:fail'){
              location.href = url
            }
          }
        )
      }
    }
  }
</script>
<style lang="less" scoped>
  .orderDetail{padding:10px;
    .box{background:#fff;border-radius:4px;height:auto;
      .hstatus{background:linear-gradient(#fcb11f,#fa7e0e);color: #fff;padding:20px 20px;}
      .contact{padding:10px 15px;
        p{padding:6px 0px;
          &.mobile{color:#999;}
        }
      }
      .h4{font-weight:700;padding:10px;border-bottom:1px solid #ddd;}
      .t{padding:10px;border-bottom:1px solid #ddd;}
      .con{padding:10px;color:#999;
        p{line-height:24px;padding:5px 0px;}
      }
      .qr{padding:15px;height:auto;text-align:center;color:#666;
        p{padding:4px 0px;display:flex;justify-content:center;}
        #qrcode{height:auto;}
      }
      .action{padding:30px 0px;text-align:center;}
  
      .ginfo{background:#fff;padding:10px;
        img{width:100%;}
        .title{font-weight:700;}
        .price{margin-top:6px;
          span{color:#fc263e;
            em{font-style:normal;font-size:20px;}
          }
          s{margin-left:20px;font-size:12px;color:#999;}
        }
        .stock{margin-top:3px;color:#999;}
      }
      .total_price{color:#fc263e;}
    }
  }
</style>
