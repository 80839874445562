<template>
  <van-cell-group class="shopInfo">
    <div class="s_name">商家信息</div>
    <van-cell v-for="(item,key) in data.contact" :key="key" :title="item.name">
      <template #label>
        <van-row type="flex" justify="space-between" align="top">
          <van-col span="4"><a :href="'tel:'+item.mobile" class="btn">拨打 <van-icon name="phone" /></a></van-col>
          <van-col span="20" class="p"><van-icon name="phone" /> {{item.mobile}}</van-col>
          
        </van-row>
        <van-row type="flex" justify="space-between" align="top">
          <van-col v-if="item.map != '' && item.map != undefined" span="4"><a @click="openLocation(item)" class="btn">导航 <van-icon name="location" /></a></van-col>
          <van-col :span="item.map != '' && item.map != undefined?20:24" class="p"><van-icon name="location" /> {{item.address}}</van-col>
          
        </van-row>
      </template>
    </van-cell>
  </van-cell-group>
</template>

<script>
  import wx from 'weixin-js-sdk'
  export default {
    props: {
      data:Object
    },
    components: {},
    data() {
      return {
        time:0
      }
    },
    mounted() {

    },
    methods: {
      openLocation(record){
        if(record.map != '' && record.map != undefined){
          let site=record.map.split(",")
          wx.openLocation({
            latitude:parseFloat(site[0]), // 纬度，浮点数，范围为90 ~ -90
            longitude:parseFloat(site[1]), // 经度，浮点数，范围为180 ~ -180。
            name:record.name, // 位置名
            address:record.address, // 地址详情说明
            scale: 1, // 地图缩放级别,整型值,范围从1~28。默认为最大
            infoUrl: '' // 在查看位置界面底部显示的超链接,可点击跳转
          });
        }
      }
    }
  }
</script>
<style lang="less" scoped>
.shopInfo{border-radius:4px;
  .van-cell{background:none;padding:12px;}
  .s_name{font-weight:700;padding:12px 15px;border-bottom:1px solid #ddd;font-size:16px;}
  .van-cell__title>span{font-size:16px;font-weight:700;color:#9c1617;}
  .van-cell__label{color:#999;font-size:14px;
    .van-col{padding:3px 0px;
      &.p{position:relative;padding-left:20px;
        .van-icon{position:absolute;left:0px;top:6px;z-index:1;}
      }
      .btn{background:#ff890a;color:#fff;padding:1px 5px;display:inline-block;border-radius:4px;font-size:12px;}
    }
  }
}
</style>
